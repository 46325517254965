import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { tokens } from '../../../theme';
import axios from "axios";
import Header from "../../../components/Header"
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import { getAuthentication } from '../../../auth';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ImageIcon from '@mui/icons-material/Image';
import PreviewIcon from '@mui/icons-material/Preview';
import { GLOBAL_API } from '../../../data/Links';
import AddNewSportEvent from './AddNewSportEvent';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import EditScore from './EditScore';
import AddMultiSportEvents from './AddMultiSportEvents';
import Events from './Events';
const API_URL = `${GLOBAL_API}/dashapi`;
const SportSeasonGQLSchema = `
id
Year
GamesCounts{
  SixBetGame
  SportBetJackPot
}
`
const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`

const CompetitionsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        Ar
        En
    }
    Gender
}
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}    
Infos{
    CreatedAt
}
`


const CompetitorsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Name{
    En
    Ar
}
isTeam
Country{
    ${CountryGQLSchema}
}
MainImage{
    FilePath
}
`
const SportEventsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Compitiors{
    Home{
        ${CompetitorsGQLSchema}
    }
    Away{
        ${CompetitorsGQLSchema}
    }
}
Year{
    ${SportSeasonGQLSchema}
}
Competition{
    ${CompetitionsGQLSchema}
}
Round{
    Round
}
Venue
StartTime
isLive
Ended
Score{
    Home
    Away
}
State{
    Winner
}
Events
`

const SportEvents = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [sportEvents,setSportEvents] = useState([])
    const [selectedSportEvent,setSelectedSportEvent] = useState()
    const [competitions,setCompetitions] = useState([])
    const [toAddNew,setToAddNew] = useState(true)
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('visible')
    const [modalStatus,setModalStatus] = useState('ADD')
    const [selectedCompetition,setSelectedCompetition] = useState('')
    const [dataCount,setDataCount] = useState()
    const [stateFilter,setStateFilter] = useState('Live')
    const handleCloseModal = () =>{
        setModalOpen(false)
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0)
    }
    
    const addNewSportEventHandler = (sport,home,away,year,competition,venue,starttime,round,apiid) =>{
        AddNewSportEventFunc(sport,home,away,year,competition,venue,starttime,round,apiid)
    }

    const addMultiSportEventsHandler = async(list_to_add) =>{
        await Promise.all(list_to_add.map(async(item) => {
            await AddNewSportEventFunc(
                item.sport,
                item.home.id,
                item.away.id,
                item.year,
                item.competition,
                item.venue,
                item.starttime
            );
        }));

        handleCalculateSportEvents()
        
    }

    const editEventScoreHandler = (home,away) => {
        editSportScoreFunc(home,away)
    }

    const handleWinnerSportEvent = (winner) => {
        editSportWinnerFunc(winner)
    }

    const ModalComponents = {
        "ADD":<AddNewSportEvent  handleSubmit={addNewSportEventHandler}  closingModal={handleCloseModal}/>,
        "EDIT":<EditScore event={selectedSportEvent}   handleWinner={handleWinnerSportEvent} handleSubmit={editEventScoreHandler} closingModal={handleCloseModal} />,
        "MULTI":<AddMultiSportEvents handleSubmit={addMultiSportEventsHandler} closingModal={handleCloseModal} />,
        "EVNT":<Events event={selectedSportEvent} closingModal={handleCloseModal} />
    }
    

    const handleNewBtnClick = () =>{
        setToAddNew(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleShowSeason = (comp) =>{
        setModalOpen(true)
        setSelectedSportEvent(comp)
        setModalStatus('SEASON')
    }

    const handleShowImageEditor = (comp) =>{
        setModalOpen(true)
        setSelectedSportEvent(comp)
        setModalStatus('IMAGE')
    } 

    const handleEditCompEditor = (comp) => {
        setModalOpen(true)
        setSelectedSportEvent(comp)
        setModalStatus('EDIT')
    }

    const showEditScoreEvent = (event) => {
        setModalOpen(true)
        setSelectedSportEvent(event)
        setModalStatus("EDIT")
    }   

    const handleAddMultiSportEvents = () =>{
        setModalOpen(true)
        setToAddNew(true)
        setModalStatus('MULTI')
    }

    const handlePreview = (comp) =>{
        setModalOpen(true)
        setSelectedSportEvent(comp)
        setModalStatus('VIEW')
    }

    const handleCalculateSportEvents = async() =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    CalculateSportEvents{
                        Key
                        Value
                    }
                }
            `
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            fetchDataCount()
        }

    }

    const AddNewSportEventFunc = async(sport, home, away, year, competition, venue, starttime, round, apiid) => {
        try {
            const res = await axios.post(API_URL, {
                query: `
                    mutation {
                        AddNewSportEvent(
                            sport: "${sport}",
                            home: "${home}",
                            away: "${away}",
                            year: "${year}",
                            competition: "${competition}",
                            venue: "${venue}",
                            starttime: "${starttime}",
                            round: "${round}",
                            apiid: "${apiid}"
                        ) {
                            ${SportEventsGQLSchema}
                        }
                    }
                `
            }, {
                headers: {
                    token: `Bearer ${getAuthentication()}`
                }
            });
    
            if (res.status === 200) {
                // If the request is successful, refresh or fetch data
                fetchData();
            }
        } catch (error) {
            // Check if it's an axios-related error (e.g., network issues or HTTP error status)
            if (error.response) {
                // Server responded with a status other than 200 range
                console.error('Error response from server:', error.response.data);
    
                // Customize error messages based on the server response
                if (error.response.status === 400) {
                    alert('Bad request: Please check the data you provided.');
                } else if (error.response.status === 401) {
                    alert('Unauthorized: Please check your authentication.');
                } else if (error.response.status === 500) {
                    alert('Server error: Please try again later.');
                } else {
                    alert(`Unexpected error: ${error.response.statusText}`);
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response from server:', error.request);
                alert('No response from server. Please check your network connection.');
            } else {
                // Something else happened during the request setup
                console.error('Error creating request:', error.message);
                alert(`Error: ${error.message}`);
            }
        }
    };
    

    const editSportWinnerFunc = async(winner) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditWinnerEvent(id:"${selectedSportEvent.id}",winner:"${winner}"){
                        ${SportEventsGQLSchema}
                    }
                }
            `
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            fetchData()
        }
    }

    const ShowEvent = (evnt) =>{
        setSelectedSportEvent(evnt)
        setModalOpen(true)

        setModalStatus('EVNT')
    }
    const editSportScoreFunc = async(home,away) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditScoreEvent(id:"${selectedSportEvent.id}",home:${home},away:${away}){
                        ${SportEventsGQLSchema}
                    }
                }
            `
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            fetchData()
        }
    }
    
    const fetchDataCount = async() =>{
        const res = await axios.post(API_URL,{
            query:`
            query{
                GetStaticNumberByKey(key:"SportEvent"){
                    Key
                    Value
                }
            }
            `
        }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })

        if(res.data.data.GetStaticNumberByKey){
            setDataCount(res.data.data.GetStaticNumberByKey.Value)
        }
        else{
            setDataCount(0)
        }

    }

    /* eslint-disable no-restricted-globals */
const setSportEventIsEnded = async (event) => {
    if (confirm("Are you sure you want to mark the sports event as ended? This action cannot be undone.")) {
        const res = await axios.post(API_URL, {
            query: `
                mutation {
                    SetSportEventIsEnded(id: "${event.id}") {
                        ${SportEventsGQLSchema}
                    }
                }
            `
        }, {
            headers: {
                token: `Bearer ${getAuthentication()}`
            }
        });

        if (res.status === 200) {
            fetchData();
        }
    }
};
/* eslint-enable no-restricted-globals */


    const fetchDataSports = async() =>{
        setLoaderVisibility('visible')
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetAllCompetitions(sport:"669c216d82167d1908e92f29"){
                        ${CompetitionsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            setCompetitions(res.data.data.GetAllCompetitions)
            setLoaderVisibility('hidden')

        }
    }
    
    const fetchData = async() =>{
        setLoaderVisibility('visible')
        fetchDataCount()
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetEvents(offset:${page},limit:${rowsPerPage},competition:"${selectedCompetition}",state:"${stateFilter}"){
                        ${SportEventsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })

        if(res.status === 200){
            setSportEvents(res.data.data.GetEvents)
            setLoaderVisibility('hidden')

        }
    }

    

    useEffect(()=>{
        fetchDataSports()
    },[])

    useEffect(()=>{
        fetchData()
    },[stateFilter])

    useEffect(()=>{
        fetchData()
    },[selectedCompetition])

    useEffect(()=>{
        fetchData()
    },[page,rowsPerPage])
  return (
    <Box m={`20px`}>
        <Header title={`Soccer Sport Events`} subtitle={`View  Sport Events`} />
        <Modal open={modalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            {ModalComponents[modalStatus]}
        </Modal>
        <Box sx={{
                mb:2,
                width:"100%",
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                justifyContent:"space-between"}}>
            <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between",gap:1}}>
                <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Sport Event</Button>
            </Box>
            <Box   sx={{display:"flex",visibility:isLoaderVisible,justifyContent:"center",alignContent:"center",alignItems:"center",}}>
                <CircularProgress color="secondary"  sx={{mx:2}}/>
                Fetching Data ... Please Wait
            </Box>
            <Box sx={{width:"40%",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
            <FormControl sx={{mt:3,width: '48%'}}>
                <InputLabel id="demo-simple-select-label" color="secondary">Select State</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stateFilter}
                    label="Sports"
                    onChange={(e)=>setStateFilter(e.target.value)}
                    color="secondary">
                        <MenuItem value="ALL">All</MenuItem>
                        <MenuItem value="Live">Live</MenuItem>
                        <MenuItem value="NotStarted">Not Started</MenuItem>
                        <MenuItem value="Ended">Ended</MenuItem>
                     
                    </Select>
                </FormControl>
            <FormControl sx={{mt:3,width: '48%'}}>
                <InputLabel id="demo-simple-select-label" color="secondary">Select A Competition</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCompetition}
                    label="Sports"
                    onChange={(e)=>setSelectedCompetition(e.target.value)}
                    color="secondary">
                        <MenuItem value="">All</MenuItem>
                        {
                            competitions.map((lC)=>(
                                <MenuItem value={lC.id}>{lC.MainInfo.Name.En} - {lC.MainInfo.Name.Ar}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
        </Box>
        <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {border: "none",},
                "& .MuiTable-root":{borderRadius:"15px"},
                "& .MuiDataGrid-cell": {borderBottom: "none",},
                "& .name-column--cell": {color: colors.greenAccent[300],},
                "& .MuiTableHead-root": {backgroundColor: colors.blueAccent[700],borderBottom: "none",},
                "& .MuiTableBody-root": {backgroundColor: colors.primary[400],},
                "& .MuiDataGrid-footerContainer": {borderTop: "none",backgroundColor: colors.blueAccent[700],},
                "& .MuiCheckbox-root": {color: `${colors.greenAccent[200]} !important`,},
                "& .MuiTableCell-root":{borderBottom:'none',textAlign:"center"},
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{color:`${colors.grey[100]} !important`}}}>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell>Sport</TableCell>
                                <TableCell>Competition / Round</TableCell>
                                <TableCell>Home</TableCell>
                                <TableCell>Away</TableCell>
                                <TableCell>Venue</TableCell>
                                <TableCell>Date/Time</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell>Score</TableCell>
                                <TableCell>Events</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    sportEvents.map((sC)=>(
                                        <TableRow key={sC.id}>
                                            <TableCell>{sC.Sport.Name.En} - {sC.Sport.Name.Ar}</TableCell>
                                            <TableCell><Typography>{sC.Competition.MainInfo.Name.En} - {sC.Competition.MainInfo.Name.Ar}</Typography><Typography>{sC?.Round?.Round}</Typography></TableCell>
                                            <TableCell>
                                                <Typography>{sC.Compitiors.Home.Name.En} - {sC.Compitiors.Home.Name.Ar}</Typography>
                                                {
                                                    sC.Compitiors.Home.isTeam && <Typography>{sC.Compitiors.Home.Country.Name.En} - {sC.Compitiors.Home.Country.Name.Ar}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{sC.Compitiors.Away.Name.En} - {sC.Compitiors.Away.Name.Ar}</Typography>
                                                {
                                                    sC.Compitiors.Away.isTeam && <Typography>{sC.Compitiors.Away.Country.Name.En} - {sC.Compitiors.Away.Country.Name.Ar}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell color='secondary'>{sC.Venue}</TableCell>
                                            <TableCell>
                                            <Typography color={`secondary`}>Local time: {new Date(sC.StartTime).toLocaleString()}</Typography>
                                            <Typography color={`secondary`}>UTC time: {sC.StartTime}</Typography>
                                                
                                            </TableCell>
                                            <TableCell>{sC.Ended? 'Ended' : sC.isLive ? 'Live' : 'Not Started'}</TableCell>
                                            <TableCell>
                                                <Typography>Home {sC.Score.Home}</Typography>
                                                <Typography>Away {sC.Score.Away}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={()=>ShowEvent(sC)}>
                                                    <RemoveRedEyeIcon />
                                                </IconButton>
                                            </TableCell>
                                         
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={dataCount}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>  
            </Box>
    </Box>
  )
}

export default SportEvents