import { useTheme } from '@emotion/react'
import React, { useRef, useState } from 'react'
import { tokens } from '../../../theme'
import { Box, Button, Divider, IconButton, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Tags from "@yaireo/tagify/dist/react.tagify" 

const EditLink = ({closingModal, handleSubmit,player}) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const tagifyRef = useRef()

    const [link,setLink] = useState(player.Infos.Link)
    const HandleSubmitBtn = () =>{
        handleSubmit(link)
        closingModal()
    }


    return (
         <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "40%",height:"40%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
            <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
                <Typography variant="h3" color="secondary" sx={{
                textAlign:"center"
                }}>Player External Link</Typography>
                <Typography sx={{textAlign:"center"}} variant="h6">{player.MainInfo.FirstName} {player.MainInfo.LastName}</Typography>
            <br/>
            <Divider />
            <br />
            <Box sx={{
                display:"flex",
                flexDirection:"row",
                justifyContent:"space-between"
            }}>
                 <TextField 
                        sx={{
                        mt:3,
                        }}
                        fullWidth
                        variant="filled"
                        type="text"
                        value={link}
                        onChange={(e)=>setLink(e.target.value)}
                        label="Link ..."
                        color="secondary"
                    />
                    
            </Box>
         
         
            <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Edit Link</Button>

        </Box>
    
    )
}

export default EditLink