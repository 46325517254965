import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery, Switch } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import CloseIcon from '@mui/icons-material/Close';
import Tags from "@yaireo/tagify/dist/react.tagify" 
import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";

const API_URL = `${GLOBAL_API}/dashapi`

const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`

const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`
const EditCompotitor = ({compotitor,handleSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const tagifyRef = useRef()

  const [countries,setCountries] = useState([])

  const [en, seten] = useState(compotitor.Name.En)
  const [ar, setar] = useState(compotitor.Name.Ar)
  const [isTeam,setIsTeam] = useState(compotitor.isTeam)
  const [selectedCountry,setSelectedCountry] = useState(compotitor.Country.id)
  const [apiId,setApiId] = useState(compotitor.ApiID)

  const HandleSubmitBtn = () =>{
    handleSubmit(en,ar,isTeam,selectedCountry,apiId)
    closingModal()
  }


  const fetchCountries = async() => {
    const res = await axios.post(API_URL,{
        query:`
            query{
                GetCountryList{
                    ${CountryGQLSchema}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })

    if(res.data.data.GetCountryList){
        setCountries(res.data.data.GetCountryList)
    }
  }
 


  useEffect(()=>{
    fetchCountries()
  },[])
  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "80%",height:"80%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Edit Compotitor</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">You Can't Delete Compotitor ... Just Edit It</Typography>
    <br/>
    <Divider />
    <br />
  

    <FormControl sx={{mt:3}} fullWidth>
      <InputLabel id="demo-simple-select-label" color="secondary">Select A Country</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedCountry}
        label="Competitions"
        onChange={(e)=>setSelectedCountry(e.target.value)}
        color="secondary"
      >
        {
          countries.map((lC)=>(
            <MenuItem value={lC.id}>{lC.Name.En} - {lC.Name.Ar}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
    
    <Box sx={{display:"flex",alignItems:"center",mt:3}}>
        <Switch checked={isTeam} onChange={()=>{setIsTeam(!isTeam)}} color="secondary" />
        <span>Check it If Team</span>
    </Box>
    <Box sx={{display:"flex",justifyContent:'space-around'}}>
          <TextField 
                  sx={{
                    mt:3,
                    width:"49%"
                  }}
                  variant="filled"
                  type="text"
                  value={en}
                  onChange={(e)=>seten(e.target.value)}
                  label="Compotitor Name English ..."
                  color="secondary"
                 
                />
          <TextField 
            sx={{
              mt:3,
              width:"49%"
            }}
            variant="filled"
            type="text"
            value={ar}
            onChange={(e)=>setar(e.target.value)}
            label="Compotitor Name Arabic ..."
            color="secondary"
          />
    </Box>
    <Box>
              <TextField 
              fullWidth
              sx={{
                mt:3,
              }}
              variant="filled"
              type="text"
              value={apiId}
              onChange={(e)=>setApiId(e.target.value)}
              label="API ID ..."
              color="secondary"
            />
            </Box>
       
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Edit Compotitor</Button>  
  </Box>
  )
}

export default EditCompotitor