import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { tokens } from "../../../theme";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { GLOBAL_API } from "../../../data/Links";
import axios from "axios";
import { getAuthentication } from "../../../auth";
const API_URL = `${GLOBAL_API}/dashapi`

const RoundGQLSchema = `
id
Round
`

const Rounds = ({closingModal,competition,season,handleSaveRounds}) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [disableBtn,setDisableBtn] = useState(true)
    const [disableSaveRounds,setDisableSaveRound] = useState(true)
    const [rounds,setRounds] = useState([])


    const saveRounds = () =>{
        handleSaveRounds(competition.Sport.id,competition.id,season.id,rounds.join(','))
        closingModal()
    }

    const retrieveRounds = async() =>{
        let config = {
            method: 'get',
            url: `${competition.Sport.ApiConfig.Link}/fixtures/rounds?league=${competition.Infos.ApiID}&season=${season.Year}`,
            headers: {
              'x-rapidapi-key': competition.Sport.ApiConfig.Key,
              'x-rapidapi-host': competition.Sport.ApiConfig.Host
            }
        }

        await axios(config).then((res)=>{
            setDisableSaveRound(false)
            setRounds(res.data.response)
        })
    }

    const fetchRounds = async() =>{
        const res = await axios.post(API_URL,{
            query:`
              query{
                  GetRoundsBySeason(season:"${season.id}"){
                    ${RoundGQLSchema}
                  }
              }
            `
          },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
          })
          if(res.data.data.GetRoundsBySeason){
            if(res.data.data.GetRoundsBySeason.length == 0){
                setDisableBtn(!disableBtn)
            }
            else{
                let fetched_rounds = []
                res.data.data.GetRoundsBySeason.map((rnd)=>{
                    fetched_rounds.push(rnd.Round)
                })
                setRounds(fetched_rounds)
            }
          }
    }

    useEffect(()=>{
        fetchRounds()
    },[])
    
    return (
        <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "40%",height:"70%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
            <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
            
            <Typography variant="h3" color="secondary" sx={{
            textAlign:"center"
            }}>Competition Rounds</Typography>
            <Typography sx={{textAlign:"center"}} variant="h6">{competition.MainInfo.Name.En} - {competition.MainInfo.Name.Ar}</Typography>
            <Typography sx={{textAlign:"center"}} variant="h6">{season.Year}</Typography>
            <br/>
            <Divider />
            <br />
            <Button onClick={retrieveRounds} disabled={disableBtn} variant="contained" color="secondary">Retrieve Rounds</Button>
            <Button sx={{mx:2}} onClick={saveRounds} disabled={disableSaveRounds} variant="contained" color="warning">Save Rounds</Button>
            <br />
            <Table>
                <TableBody>
                    {
                        rounds && rounds.map((rnd)=>(
                            <TableRow><TableCell>{rnd}</TableCell></TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Box>
    )
}

export default Rounds