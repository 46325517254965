import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Box, IconButton, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import CloseIcon from '@mui/icons-material/Close';

const Events = ({ closingModal, event }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Parse the stringified event data
  let eventData = [];
  try {
    eventData = JSON.parse(event.Events); // Parse the stringified event data
  } catch (error) {
    console.error("Error parsing event data:", error);
  }

  // Assuming home and away team IDs are available
  const homeTeamId = eventData[0]?.team.id;  // Just an example, adjust based on real data
  const awayTeamId = eventData[1]?.team.id;  // Adjust based on real data

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '95%',
        bgcolor: colors.primary[400],
        overflowY: 'scroll',
        boxShadow: 24,
        p: 4,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={() => closingModal()} color="error">
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography variant="h3" color="secondary" sx={{ textAlign: 'center' }}>
        Event Timeline
      </Typography>
      <Typography sx={{textAlign:"center"}} variant="h6">{event.Compitiors.Home.Name.En} <span style={{margin:10,color:"red"}}>VS</span> {event.Compitiors.Away.Name.En}</Typography>

      <Timeline position="alternate">
        {eventData.map((eventItem, index) => {
          // Determine if the event is from the home or away team
          const isHomeTeam = eventItem.team.id === homeTeamId;

          return (
            <TimelineItem
              key={index}
              position={isHomeTeam ? 'left' : 'right'}  // Home team on the left, away team on the right
            >
              <TimelineSeparator>
                <TimelineDot
                  color={
                    eventItem.type === 'Goal'
                      ? 'primary'
                      : eventItem.type === 'Card'
                      ? 'warning'
                      : 'secondary'
                  }
                />
                {index !== eventData.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Box display="flex" flexDirection={isHomeTeam? `row-reverse`:'row'}  alignItems="center">
                  <img
                    src={`https://media.api-sports.io/football/players/${eventItem.player.id}.png`}
                    alt={eventItem.player.name}
                    style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10,marginLeft:10 }}
                  />
                  <Box>
                    <Typography variant="h6" component="span">
                      {eventItem.time.elapsed}' - {eventItem.player.name}
                    </Typography>
                    <Typography>
                      {eventItem.team.name} - {eventItem.detail}
                    </Typography>
                    {eventItem.comments && (
                      <Typography variant="body2" color="textSecondary">
                        {eventItem.comments}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
};

export default Events;
