import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { tokens } from '../../../theme';
import axios from "axios";
import Header from "../../../components/Header"
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import { getAuthentication } from '../../../auth';
import AddNewCompetition from './AddNewCompetition';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ImageIcon from '@mui/icons-material/Image';
import Season from './Season';
import EditImage from './EditImage';
import PreviewIcon from '@mui/icons-material/Preview';
import { GLOBAL_API } from '../../../data/Links';
import EditCompetition from './EditCompetition';
import View from './View';
import Rounds from './Rounds';
const API_URL = `${GLOBAL_API}/dashapi`;
const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
ApiConfig{
    Link
    Key
    Host
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`
const CompetitionsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        Ar
        En
    }
    Gender
}
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}    
Infos{
    CreatedAt
    ApiID
}
`

const Competitions = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [Competitions,setCompetitions] = useState([])
    const [selectedCompetition,setSelectedCompetition] = useState()
    const [selectedSeason,setSelectedSeason] = useState()

    const [toAddNew,setToAddNew] = useState(true)
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('visible')
    const [modalStatus,setModalStatus] = useState('ADD')
    const [dataCount,setDataCount] = useState()

    const [sports,setSports] = useState([])
    const [selectedSport,setSelectedSport] = useState('')
    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0)
    }
    const addNewCompitionHandler = (sport,en,ar,gender,keywords,description,apiId) =>{
        addNewCompitionFunc(sport,en,ar,gender,keywords,description,apiId)
    }

    const editIamgeHandler = (filepath) => {
        editIamgeFunc(filepath)
    }
    
    const editCompetitionHandler = (en,ar,gender,keywords,description,apiId) =>{
        editCompetitionFunc(en,ar,gender,keywords,description,apiId)
    }

    const handleAddNewSeasons = (year) => {
        AddNewSportSeasonFunc(year)
    }

    const previewRoundHandler = (season) =>{
        setSelectedSeason(season)
        setModalStatus('RUND')
    }

    const handleSaveRounds = (sport,competition,season,rounds) =>{
        saveRounds(sport,competition,season,rounds)
    }

    const ModalComponents = {
        "ADD":<AddNewCompetition closingModal={handleCloseModal} handleSubmit={addNewCompitionHandler} />,
        "SEASON":<Season handleSubmit={handleAddNewSeasons} competition={selectedCompetition} closingModal={handleCloseModal} previewRoundHandler={previewRoundHandler} />,
        "IMAGE":<EditImage competition={selectedCompetition} handleSubmit={editIamgeHandler} closingModal={handleCloseModal} />,
        "EDIT":<EditCompetition competition={selectedCompetition} handleSubmit={editCompetitionHandler} closingModal={handleCloseModal} />,
        "RUND":<Rounds closingModal={handleCloseModal} competition={selectedCompetition} season={selectedSeason} handleSaveRounds={handleSaveRounds} />,
        "VIEW":<View competition={selectedCompetition} closingModal={handleCloseModal} />
    }
    

    const handleNewBtnClick = () =>{
        setToAddNew(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleShowSeason = (comp) =>{
        setModalOpen(true)
        setSelectedCompetition(comp)
        setModalStatus('SEASON')
    }

    const handleShowImageEditor = (comp) =>{
        setModalOpen(true)
        setSelectedCompetition(comp)
        setModalStatus('IMAGE')
    } 

    const handleEditCompEditor = (comp) => {
        setModalOpen(true)
        setSelectedCompetition(comp)
        setModalStatus('EDIT')
    }

    const handlePreview = (comp) =>{
    
    }


    const saveRounds = async (sport,competition,season,rounds) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    AddRoundsToCompetition(sport:"${sport}",comp:"${competition}",season:"${season}",rounds:"${rounds}")
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            fetchData()
        }
    }

    const addNewCompitionFunc = async(sport,en,ar,gender,keywords,description,apiId) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    AddNewCompetition(sport:"${sport}",en:"${en}",ar:"${ar}",gender:"${gender}",country:"",keywords:"${keywords.join(',')}",description:${JSON.stringify(description)},apiid:"${apiId}"){
                        ${CompetitionsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            fetchData()
        }
    }

    const editIamgeFunc = async(filepath) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditCompetitionImage(id:"${selectedCompetition.id}",filepath:"${filepath}"){
                        ${CompetitionsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            fetchData()
        }
        
    }

    const AddNewSportSeasonFunc = async(year) =>{
        const res = await axios.post(API_URL,{
            query:`mutation{
                AddNewSportSeason(sport:"${selectedCompetition.Sport.id}",competition:"${selectedCompetition.id}",year:"${year}"){
                   id
                }
            }`
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.AddNewSportSeason){
            fetchData()
        }
    }

    const editCompetitionFunc = async(en,ar,gender,keywords,description,apiId) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditCompetition(id:"${selectedCompetition.id}",en:"${en}",ar:"${ar}",gender:"${gender}",country:"",keywords:"${keywords.join(',')}",description:${JSON.stringify(description)},apiid:"${apiId}"){
                        ${CompetitionsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            fetchData()
        }
    }

    const fetchDataCount = async() =>{
        const res = await axios.post(API_URL,{
            query:`
            query{
                GetStaticNumberByKey(key:"Competition"){
                    Key
                    Value
                }
            }
            `
        }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })

        if(res.data.data.GetStaticNumberByKey){
            setDataCount(res.data.data.GetStaticNumberByKey.Value)
        }
        else{
            setDataCount(0)
        }

    }

    const fetchDataSports = async() =>{
        setLoaderVisibility('visible')
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetSports{
                        ${SportGQLSCHEMA}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })

        if(res.status === 200){
            setSports(res.data.data.GetSports)
            setLoaderVisibility('hidden')

        }
    }

    const fetchData = async() =>{
        setLoaderVisibility('visible')
        fetchDataCount()
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetCompetitions(offset:${page},limit:${rowsPerPage},sport:"${selectedSport}"){
                        ${CompetitionsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })

        if(res.status === 200){
            setCompetitions(res.data.data.GetCompetitions)
            setLoaderVisibility('hidden')

        }
    }

    useEffect(()=>{
        fetchData()
    },[selectedSport])

    useEffect(()=>{
        fetchDataSports()
    },[])
    useEffect(()=>{
        fetchData()
    },[page,rowsPerPage])
  return (
    <Box m={`20px`}>
        <Header title={` Competitions`} subtitle={`Add, Edit  Competitions`} />
        <Modal open={modalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            {ModalComponents[modalStatus]}
        </Modal>
        <Box sx={{
                mb:2,
                width:"100%",
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                justifyContent:"space-between"}}>
            <Box>
                <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Competition</Button>
            </Box>
            <Box   sx={{display:"flex",visibility:isLoaderVisible,justifyContent:"center",alignContent:"center",alignItems:"center",}}>
                <CircularProgress color="secondary"  sx={{mx:2}}/>
                Fetching Data ... Please Wait
            </Box>
            <Box sx={{width:"30%"}}>
            <FormControl sx={{mt:3}} fullWidth>
                <InputLabel id="demo-simple-select-label" color="secondary">Select A Sport</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedSport}
                    label="Sports"
                    onChange={(e)=>setSelectedSport(e.target.value)}
                    color="secondary"
                    >
                        <MenuItem value="">All</MenuItem>
                        {
                            sports.map((lC)=>(
                                <MenuItem value={lC.id}>{lC.Name.En} - {lC.Name.Ar}</MenuItem>
                            ))
                        }
                        
                    </Select>
                </FormControl>

            </Box>
               

        </Box>

        <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {border: "none",},
                "& .MuiTable-root":{borderRadius:"15px"},
                "& .MuiDataGrid-cell": {borderBottom: "none",},
                "& .name-column--cell": {color: colors.greenAccent[300],},
                "& .MuiTableHead-root": {backgroundColor: colors.blueAccent[700],borderBottom: "none",},
                "& .MuiTableBody-root": {backgroundColor: colors.primary[400],},
                "& .MuiDataGrid-footerContainer": {borderTop: "none",backgroundColor: colors.blueAccent[700],},
                "& .MuiCheckbox-root": {color: `${colors.greenAccent[200]} !important`,},
                "& .MuiTableCell-root":{borderBottom:'none',textAlign:"center"},
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{color:`${colors.grey[100]} !important`}}}>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell>Competition Name</TableCell>
                                <TableCell>Sport</TableCell>
                                <TableCell>Seasons</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Competitions.map((sC)=>(
                                        <TableRow key={sC.id}>
                                            <TableCell>{sC.MainInfo.Name.En} - {sC.MainInfo.Name.Ar}</TableCell>
                                            <TableCell>{sC.Sport.Name.En} - {sC.Sport.Name.Ar}</TableCell>
                                            <TableCell color='secondary'>
                                                <IconButton onClick={()=>handleShowSeason(sC)}>
                                                    <RemoveRedEyeIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={()=>handlePreview(sC)}>
                                                    <PreviewIcon />
                                                </IconButton>
                                                <IconButton onClick={()=>handleShowImageEditor(sC)} color='secondary'>
                                                    <ImageIcon />
                                                </IconButton>
                                                <IconButton onClick={()=>handleEditCompEditor(sC)} >
                                                    <EditIcon />
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[1,5,10, 25, 50, 100]}
                        component="div"
                        count={dataCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />  
            </Box>
    </Box>
  )
}

export default Competitions