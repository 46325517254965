import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery, Switch, Autocomplete, TableHead, TableRow, Table, TableCell, TableBody, CircularProgress } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";

const API_URL = `${GLOBAL_API}/dashapi`
const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`
const SportSeasonGQLSchema = `
id
Year
GamesCounts{
  SixBetGame
  SportBetJackPot
}
`
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
ApiConfig{
  Link
  Key
  Host
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`

const CompetitionsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        Ar
        En
    }
    Gender
}
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}    
Infos{
    ApiID
    CreatedAt
}
`
const CompetitorsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Name{
    En
    Ar
}
isTeam
Country{
    ${CountryGQLSchema}
}
MainImage{
    FilePath
}
`

const RoundGQLSchema = `
id
Round
`

const AddNewSportEvent = ({handleSubmit,closingModal}) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [compettitons,setCompetitions] = useState([])
  const [seasons,setSeasons] = useState([])  
  const [rounds,setRounds] = useState([])

  const [isLoaderVisible,setLoaderVisibility] = useState(false)



  const [selectedSport,setSelectedSport] = useState("669c216d82167d1908e92f29")
  const [selectedCompetition,setSelectedCompetition] = useState(null)
  const [selectedSeason,setSelectedSeason] = useState(null)
  const [selectedRound,setSelectedRound] = useState(null)

  const [fetchedSportEvents,setFetchedSportEvents] = useState([])

  const [disableSubmit,setDisableSubmit] = useState(true)

  const HandleSubmitBtn = async () => {
    if (fetchedSportEvents.length > 0) {
      for (const event of fetchedSportEvents) {
        const starttime = new Date(event.fixture.date).toISOString();
        await handleSubmit(selectedSport, event.teams.home.id, event.teams.away.id, selectedSeason.id, selectedCompetition.id, `${event.fixture.venue.name} - ${event.fixture.venue.city}`, starttime, selectedRound.id,event.fixture.id);
      }
    }
    closingModal()
  }
  

  const fetchCompetitorData = async (teamId) => {
    // Fetch competitor by the team ID (ApiID) from your API
    const res = await axios.post(API_URL, {
        query: `
            query {
                GetCompotitorsByAPIID(ids: "${teamId}") {
                    ${CompetitorsGQLSchema}
                }
            }
        `
    }, {
        headers: {
            token: `Bearer ${getAuthentication()}`
        }
    });

    // Return competitor data if found, or fallback to null if not
    return res.data.data.GetCompotitorsByAPIID || null;
}

const fetchSportEvents = async () => {
  setLoaderVisibility(true)
  console.log('Fetching sport events...');

  let config = {
      method: 'get',
      url: `${selectedCompetition.Sport.ApiConfig.Link}/fixtures?league=${selectedCompetition.Infos.ApiID}&season=${selectedSeason.Year}&round=${encodeURI(selectedRound.Round)}`,
      headers: {
          'x-rapidapi-key': selectedCompetition.Sport.ApiConfig.Key,
          'x-rapidapi-host': selectedCompetition.Sport.ApiConfig.Host
      }
  };

  const res = await axios(config);
  let fetchedEvents = res.data.response;

  // Map over the fetched events to replace the teams data with your own competitor data
  const updatedEvents = await Promise.all(fetchedEvents.map(async (event) => {
      const homeCompetitor = await fetchCompetitorData(event.teams.home.id);
      const awayCompetitor = await fetchCompetitorData(event.teams.away.id);
      
      // Log fetched competitors for debugging

      // Only return the event if both homeCompetitor and awayCompetitor are found
      if (homeCompetitor[0] && awayCompetitor[0]) {
          return {
              ...event,
              teams: {
                  home: homeCompetitor[0], // assuming homeCompetitor is an array
                  away: awayCompetitor[0]  // assuming awayCompetitor is an array
              }
          };
      } else {
        return {
          ...event,
          teams: {
              home: homeCompetitor[0] ? homeCompetitor[0] :{
                Name:{
                  En:event.teams.home.name+ ' Not Imported'
                }
              }, // assuming homeCompetitor is an array
              away: awayCompetitor[0]  ? awayCompetitor[0] : {
                Name:{
                  En:event.teams.away.name+ ' Not Imported'
                }
              } // assuming awayCompetitor is an array
          }
      }; // return null if one or both competitors are not found
      }
  }));

  // Filter out null values (events where one or both competitors were missing)
  const filteredEvents = updatedEvents.filter(event => event !== null);

  // Update the state with the filtered events
  setFetchedSportEvents(filteredEvents);
  setLoaderVisibility(false)

};

 
  const fetchSeasons = async() =>{
    const res = await axios.post(API_URL,{
        query:`
            query{
                GetSportSeason(id:"${selectedCompetition.id}"){
                    ${SportSeasonGQLSchema}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.GetSportSeason){
        setSeasons(res.data.data.GetSportSeason)
    }
  }

  const fetchCompetitions = async() =>{
   
    const res = await axios.post(API_URL,{
        query:`
        query{
            GetAllCompetitions(sport:"${selectedSport}"){
                ${CompetitionsGQLSchema}
            }
        }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.GetAllCompetitions){
        setCompetitions(res.data.data.GetAllCompetitions)
    }
  }
 





  const fetchRounds = async() =>{
    const res = await axios.post(API_URL,{
        query:`
          query{
              GetRoundsBySeason(season:"${selectedSeason.id}"){
                ${RoundGQLSchema}
              }
          }
        `
      },{
        headers:{
          token:`Bearer ${getAuthentication()}`
        }
      })
      if(res.data.data.GetRoundsBySeason){
     
        setRounds(res.data.data.GetRoundsBySeason)
      }
}
 


useEffect(()=>{
  if(selectedCompetition !== null && selectedSeason !== null && selectedRound !== null){
    fetchSportEvents()
  }
},[selectedRound])
  

useEffect(()=>{
  if(selectedSeason !== null){
      fetchRounds()
      setSelectedRound(null)
  }
},[selectedSeason])



  useEffect(()=>{
    if(selectedCompetition !== null){
        fetchSeasons()
        setSelectedSeason(null)
    }
  },[selectedCompetition])

 



  useEffect(()=>{
    if(selectedSport !== null){
      fetchCompetitions()
      setSelectedCompetition(null)
    } 
  },[])

  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "95%",height:"95%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Add New Sport Event</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">You Can't Delete Sport Event ... You Can Just Edit The Score</Typography>
    <br/>
    <Divider />
    <br />

    <Box sx={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-start",
        gap:4
    }}>
   
    
    {
        compettitons.length > 0 &&   <FormControl sx={{mt:3 ,width:'33%'}} >
        <InputLabel id="demo-simple-select-label" color="secondary">Select A Competiton</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedCompetition}
          label="Competitions"
          onChange={(e)=>setSelectedCompetition(e.target.value)}
          color="secondary"
        >
          {
            compettitons.map((lC)=>(
              <MenuItem value={lC}>{lC.MainInfo.Name.En} - {lC.MainInfo.Name.Ar}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    }

{
        seasons.length > 0 &&   <FormControl sx={{mt:3 ,width:'33%'}} >
        <InputLabel id="demo-simple-select-label" color="secondary">Select A Season</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedSeason}
          label="Competitions"
          onChange={(e)=>setSelectedSeason(e.target.value)}
          color="secondary"
        >
          {
            seasons.map((lC)=>(
              <MenuItem value={lC}>{lC.Year}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    }
    {
        rounds.length > 0 &&   <FormControl sx={{mt:3 ,width:'33%'}} >
        <InputLabel id="demo-simple-select-label" color="secondary">Select A Round</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedRound}
          label="Competitions"
          onChange={(e)=>setSelectedRound(e.target.value)}
          color="secondary"
        >
          {
            rounds.map((lC)=>(
              <MenuItem value={lC}>{lC.Round}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    }
    </Box>
    {
      isLoaderVisible &&  <Box   sx={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center",}}>
      <CircularProgress color="secondary"  sx={{mx:2}}/>
      Fetching Data ... Please Wait
  </Box>
    }
   
    <Box>
        <Table>
            <TableHead>
              <TableRow>
              <TableCell>Hone</TableCell>
              <TableCell>Away</TableCell>
              <TableCell>Venue</TableCell>
              <TableCell>Start time</TableCell>
              <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                fetchedSportEvents.map((rnd)=>(
                  <TableRow>
                    <TableCell >
                        <img width={25} style={{borderRadius:'100%'}} src={`https://cloud.time4.games/${rnd.teams.home?.MainImage?.FilePath}`} />
                        <Typography>{rnd.teams.home?.Name?.En}</Typography>
                    </TableCell>
                    <TableCell >
                        <img width={25} style={{borderRadius:'100%'}} src={`https://cloud.time4.games/${rnd.teams.away?.MainImage?.FilePath}`} />
                        <Typography>{rnd.teams.away?.Name?.En}</Typography>
                    </TableCell>
                    <TableCell>
                        {rnd.fixture.venue.name} - {rnd.fixture.venue.city}
                    </TableCell>
                    <TableCell>
                        {new Date(rnd.fixture.date).toLocaleString()}
                    </TableCell>
                    <TableCell>
                        {rnd.fixture.status.long} {rnd.fixture.status.long === 'Match Finished' && <><Typography>Home {rnd.goals.home}</Typography><Typography>Away {rnd.goals.away}</Typography></>}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>  
    </Box>  
    <br />  
    <Box>
    <Switch onChange={()=>fetchedSportEvents.length > 0 && setDisableSubmit(!disableSubmit)} checked={!disableSubmit} color="secondary" />
    <span>Activate Submit Button</span>
    </Box>
    <Button disabled={disableSubmit} onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Sport Event</Button>  
  </Box>
  )
}

export default AddNewSportEvent