import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery,Card, CardContent, CardHeader, Grid, TableContainer, Table, TableBody, TableRow, TableCell, Switch, RadioGroup, FormLabel, Radio } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CloseIcon from '@mui/icons-material/Close';
import FlagIcon from '@mui/icons-material/Flag'; 
import 'react-image-crop/dist/ReactCrop.css'
import DeleteIcon from '@mui/icons-material/Delete';

import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";
const API_URL = `${GLOBAL_API}/dashapi`
const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`

const SportSeasonGQLSchema = `
id
Year
GamesCounts{
  SixBetGame
  SportBetJackPot
}
`
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`
const CompetitorsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Name{
    En
    Ar
}
isTeam
Country{
    ${CountryGQLSchema}
}
MainImage{
    FilePath
}
`
const CompetitionsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        Ar
        En
    }
    Gender
}
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}    
Infos{
    CreatedAt
}
`
const SportEventsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Compitiors{
    Home{
        ${CompetitorsGQLSchema}
    }
    Away{
        ${CompetitorsGQLSchema}
    }
}
Year{
    ${SportSeasonGQLSchema}
}
Competition{
    ${CompetitionsGQLSchema}
}
Venue
StartTime
isLive
Ended
Score{
    Home
    Away
}
`
const SoccerWinPredictionGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        En
        Ar
    }
    Slug
    StartDate
    Composition{
        ${CompetitionsGQLSchema}
    }
}
MainImage{
    FilePath
}
SportEvents{
   ${SportEventsGQLSchema}
}
Participants{
    id
}
Awards{
    Index
    isWin
    Award
}
Infos{
    SportEventsReady
    ParticipantsCount
    Published
    Locked
    CreatedAt
}
`


const SportEvents = ({competition,handleSubmit,handlePull,setAsDone,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [loadedSeasons,setLoadedSeasons] = useState([])
  const [schedual,setSchedual] = useState([])
  const [sportEvents,setSportEvents] = useState([])
  const [selectedMatch,setSelectedMatch] = useState(null)
 


  const handleAddSportEvent = () =>{
    handleSubmit(selectedMatch.id)
  }

  const deleteEvent = (qa) =>{
    handlePull(qa.id)
  } 

 
    const handleSetAsDone = () =>{
        const confirmed = window.confirm("Are you sure you want to perform this action? This change cannot be undone.")
        if(confirmed){
            setAsDone()
            closingModal()
        }
    }
 

   const getMatches = async() =>{
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetAllEvents(sport:"${competition.Sport.id}",competition:"${competition.MainInfo.Composition.id}",season:"${competition.MainInfo.Season.id}"){
                        ${SportEventsGQLSchema}
                    }
                }
            `
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        }) 
        if(res.data.data.GetAllEvents){
            setSportEvents(res.data.data.GetAllEvents)
        }
   }   
   
    useEffect(()=>{
        getMatches()
      },[competition])
  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "90%",height:"90%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Sport Events</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">{competition.MainInfo.Name.En} - {competition.MainInfo.Name.Ar}</Typography>
    <br/>
    <Divider />
    <br />
    <Box sx={{display:"flex",justifyContent:"space-around",flexDirection:"row"}}>
        <Box sx={{width:"45%"}}>
        {
            sportEvents.length > 0 && <FormControl sx={{mt:3}} fullWidth>
            <InputLabel id="demo-simple-select-label" color="secondary">Select A Match</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedMatch}
                onChange={(e)=>setSelectedMatch(e.target.value)}
                label="Competitions"
                color="secondary"
            >
                {
                sportEvents.map((lC)=>(
                    <MenuItem value={lC}>({lC.Compitiors.Home.Name.En} - {lC.Compitiors.Home.Name.Ar})  VS  ({lC.Compitiors.Away.Name.En} - {lC.Compitiors.Away.Name.Ar})</MenuItem>
                ))
                }
            </Select>
            </FormControl>
        }
          


            {

                selectedMatch && <Box sx={{mt:3}}>
                <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center",flexDirection:"row"}}>
                    <Box sx={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
                        <img style={{borderRadius:180,width:75,marginBottom:4}} src={"https://cloud.time4.games/"+selectedMatch.Compitiors.Home.MainImage.FilePath} />
                        <Typography variant="h4">{selectedMatch.Compitiors.Home.Name.En} - {selectedMatch.Compitiors.Home.Name.Ar}</Typography>
                        {
                            selectedMatch.Compitiors.Home.isTeam && <Typography variant="h6">{selectedMatch.Compitiors.Home.Country.Name.En} - {selectedMatch.Compitiors.Home.Country.Name.Ar}</Typography>
                        }                 
                   </Box>
                    <Box>
                        <Typography variant="h1" sx={{fontWeight:"bolder"}}>VS</Typography>
                    </Box>
                    <Box sx={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
                    <img style={{borderRadius:180,width:75,marginBottom:4}} src={"https://cloud.time4.games/"+selectedMatch.Compitiors.Away.MainImage.FilePath} />
                    <Typography variant="h4">{selectedMatch.Compitiors.Away.Name.En} - {selectedMatch.Compitiors.Away.Name.Ar}</Typography>
                        {
                            selectedMatch.Compitiors.Away.isTeam && <Typography variant="h6">{selectedMatch.Compitiors.Away.Country.Name.En} - {selectedMatch.Compitiors.Away.Country.Name.Ar}</Typography>
                        }
                    </Box>
                </Box>
                <Box sx={{justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",mt:3,gap:1}}>
                    <Typography variant="h4">{new Date(selectedMatch.StartTime).toLocaleDateString()}</Typography>
                    <Typography variant="h5">{new Date(selectedMatch.StartTime).toLocaleTimeString()}</Typography>
                    <Typography variant="h6">Date in UTC: {selectedMatch.StartTime}</Typography>
                    <Typography variant="h6">{selectedMatch.Venue}</Typography>
                </Box>
            </Box>
            }
            {
                !competition.Infos.SportEventsReady && <Button onClick={handleAddSportEvent} sx={{mt:2,width:"100%"}} variant="contained" color="secondary">Add To Events</Button>
            }
              

        </Box>
        <Box sx={{width:"45%"}}>
            <Box sx={{display:"flex",alignItems:"center"}}>
                <Switch checked={competition.Infos.SportEventsReady} color="secondary" disabled={competition.Infos.SportEventsReady} onChange={()=>handleSetAsDone()}/>
                <Typography>Set Sport Event is ready</Typography>

            </Box>
            <TableContainer>
            <Typography> Sport Events Count {competition.SportEvents.length}</Typography>

                <Table>
                    <TableBody>
                        {
                            competition.SportEvents.map((sE)=>(
                                <TableRow key={sE.EventId}>
                                    <TableCell><Typography variant="h6">{new Date(sE.StartTime).toLocaleString()}</Typography>
                                        <Typography variant="h6">Date in UTC: {sE.StartTime}</Typography>
                                    </TableCell>                   
                                     <TableCell>{sE.Compitiors.Home.Name.En} - {sE.Compitiors.Home.Name.Ar}</TableCell>
                                    <TableCell>{sE.Compitiors.Away.Name.En} - {sE.Compitiors.Away.Name.Ar}</TableCell>
                                    {
                                        !competition.Infos.SportEventsReady &&
                                    <TableCell>
                                        <IconButton onClick={()=>deleteEvent(sE)} color="error">
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </Box>
  </Box>
  )
}

export default SportEvents