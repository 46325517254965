import { useTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react'
import { tokens } from '../../../theme';
import axios from 'axios';
import { getAuthentication } from '../../../auth';
import Header from '../../../components/Header';
import { Box, Button, CircularProgress, IconButton, Modal, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { GLOBAL_API } from '../../../data/Links';
import EditIcon from '@mui/icons-material/Edit';

import EditLink from './EditLink';
const API_URL = `${GLOBAL_API}/dashapi`;

const SoccerPlayerGQLSchema = `
id
MainInfo{
    FirstName
    LastName
}
Infos{
    ApiID
    Link
}
`
const SoccerPlayer = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [dataCount,setDataCount] = useState(0)

    const [players,setPlayers] = useState([])
    const [selectedPlayer,setSelectedPlayer] = useState(null)
    const [searchQuery,setSearchQuery] = useState('')
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('EDIT')



    const editLinkHandler = (link) =>{
      editSportFunc(link)
  }
  const handleCloseModal = () =>{
      setModalOpen(false)
  }
   
    const ModalComponents = {
      "EDIT":<EditLink player={selectedPlayer} handleSubmit={editLinkHandler} closingModal={handleCloseModal} />
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0)
    }

    const editSportFunc = async(link) =>{
      const res = await axios.post(API_URL,{
        query:`
          mutation{
            EditSoccerPlayerLink(id:"${selectedPlayer.id}",link:"${link}"){
              ${SoccerPlayerGQLSchema}
            }
          }
        `
      },{
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
       })
       if(res.data.data.EditSoccerPlayerLink){
          fetchData()
       }
    }
   

    const showEdit = (player) =>{
      setSelectedPlayer(player)
      setModalOpen(true)
    }
    const fetchDataCount = async() =>{
        const res = await axios.post(API_URL,{
            query:`
            query{
                GetStaticNumberByKey(key:"Player"){
                    Key
                    Value
                }
            }
            `
        }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })

        if(res.data.data.GetStaticNumberByKey){
            setDataCount(res.data.data.GetStaticNumberByKey.Value)
        }
        else{
            setDataCount(0)
        }

    }

    
    const fetchData = async() =>{
        setLoaderVisibility('flex')

        const res = await axios.post(API_URL, {
            query: `
              query {
                GetSoccerPlayers(limit:${rowsPerPage},offset:${page},query:"${searchQuery}"){
                  ${SoccerPlayerGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.GetSoccerPlayers){
            setPlayers(res.data.data.GetSoccerPlayers)
            setLoaderVisibility('none')
        }
    }

   useEffect(()=>{
    fetchData()
   },[page,rowsPerPage,searchQuery])

    

    useEffect(()=>{
        fetchDataCount()
        fetchData()
    },[])
    return (
        <Box m="20px">
            <Header title={`Soccer Players`}  />
            <Modal open={modalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              {ModalComponents[modalStatus]}
            </Modal>
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>

                <Box   sx={{
                                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                                justifyContent:"center",
                                alignContent:"center",
                                alignItems:"center",
                            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'none' : 'flex',
                justifyContent:"flex-start",
                alignContent:"flex-start",
                alignItems:"flex-start",
                width: '70%',
            }}>
                       
                    </Box>
            </Box>
            <Box>
              <TextField value={searchQuery} onChange={(e)=>setSearchQuery(e.target.value)} sx={{width:"30%"}} label="Search ..." color='secondary' />
            </Box>
            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
                <TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Player</TableCell>
        <TableCell>First name</TableCell>
        <TableCell>Last name</TableCell>
        <TableCell>Preview</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
        {
            players.map((ad)=>(
                <TableRow key={ad.id}>
                    <TableCell>
                        <img
                            src={`https://media.api-sports.io/football/players/${ad.Infos.ApiID}.png`}
                            style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10,marginLeft:10 }}
                        />
                    </TableCell>
                    <TableCell>{ad.MainInfo.FirstName}</TableCell>
                    <TableCell>{ad.MainInfo.LastName}</TableCell>
                    <TableCell>
                      {
                        ad.Infos.Link !== '' && <a style={{color:"white"}} target='_blank' href={ad.Infos.Link}>Preview</a>
                      }
                        
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={()=>showEdit(ad)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                </TableRow>
            ))
        }
    </TableBody>
    

   </Table>
    </TableContainer>
    <TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={dataCount}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>  
              </Box>
        </Box>
    )
}

export default SoccerPlayer