import { Box, Button, CircularProgress, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import ImageIcon from '@mui/icons-material/Image';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { getAuthentication } from '../../../auth';
import { GLOBAL_API } from '../../../data/Links';
import AddNewSport from './AddNewSport';
import EditImage from './EditImage';
import EditSport from './EditSport';
const API_URL = `${GLOBAL_API}/dashapi`;

const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
ApiConfig{
    Link
    Host
    Key
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`
const Sports = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const [modalStatus,setModalStatus] = useState('ADD')
    const [toAddNew,setToAddNew] = useState(true)
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('visible')
    const [selectedSport,setSelectedSprot] = useState(null)
    
    const [Sports,setSports] = useState([])

    const addNewSportHandler = (en,ar,keywords,description,link,key,host) =>{
        addNewSportFunc(en,ar,keywords,description,link,key,host)
    }

    const editIamgeHandler = (filepath) => {
        editIamgeFunc(filepath)
    }

    const editSportHandler = (en,ar,keywords,description,link,key,host) =>{
        editSportFunc(en,ar,keywords,description,link,key,host)
    }
    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    const ModalComponents = {
        "ADD":<AddNewSport closingModal={handleCloseModal} handleSubmit={addNewSportHandler} />,
        "IMAGE":<EditImage sport={selectedSport} handleSubmit={editIamgeHandler} closingModal={handleCloseModal} />,
        "EDIT":<EditSport sport={selectedSport} handleSubmit={editSportHandler} closingModal={handleCloseModal} />,
    }

    

    const handleNewBtnClick = () =>{
        setToAddNew(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleShowImageEditor = (sprt) =>{
        setModalOpen(true)
        setSelectedSprot(sprt)
        setModalStatus('IMAGE')
    }

    const handleEditSportEditor = (sprt) => {
        setModalOpen(true)
        setSelectedSprot(sprt)
        setModalStatus('EDIT')

    }

    const addNewSportFunc = async(en,ar,keywords,description,link,key,host) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    AddNewSport(en:"${en}",ar:"${ar}",keywords:"${keywords.join(',')}",description:${JSON.stringify(description)},link:"${link}",key:"${key}",host:"${host}"){
                        ${SportGQLSCHEMA}
                    }
                }
            `
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.AddNewSport.id){
            fetchData()
        }
    }

    const editSportFunc = async(en,ar,keywords,description,link,key,host) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditSport(id:"${selectedSport.id}",en:"${en}",ar:"${ar}",keywords:"${keywords.join(',')}",description:${JSON.stringify(description)},link:"${link}",key:"${key}",host:"${host}"){
                        ${SportGQLSCHEMA}
                    }
                }
            `
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditSport.id){
            fetchData()
        }
    }

    const editIamgeFunc = async(filepath) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditSportImage(id:"${selectedSport.id}",filepath:"${filepath}"){
                        ${SportGQLSCHEMA}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            fetchData()
        }
        
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0)
    }

    const fetchData = async() =>{
        setLoaderVisibility('visible')
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetSports{
                        ${SportGQLSCHEMA}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })

        if(res.status === 200){
            setSports(res.data.data.GetSports)
            setLoaderVisibility('hidden')

        }
    }

    useEffect(()=>{
        fetchData()
    },[])

  return (
    <Box m={`20px`}>
        <Header title={`Sports`} subtitle={`Add,Edit Sports`} />
        <Modal open={modalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            {ModalComponents[modalStatus]}
        </Modal>
       <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"}}>
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Sport</Button>

                    <Box   sx={{display:"flex",visibility:isLoaderVisible,justifyContent:"center",alignContent:"center",alignItems:"center",}}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>

        </Box>

        <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {border: "none",},
                "& .MuiTable-root":{borderRadius:"15px"},
                "& .MuiDataGrid-cell": {borderBottom: "none",},
                "& .name-column--cell": {color: colors.greenAccent[300],},
                "& .MuiTableHead-root": {backgroundColor: colors.blueAccent[700],borderBottom: "none",},
                "& .MuiTableBody-root": {backgroundColor: colors.primary[400],},
                "& .MuiDataGrid-footerContainer": {borderTop: "none",backgroundColor: colors.blueAccent[700],},
                "& .MuiCheckbox-root": {color: `${colors.greenAccent[200]} !important`,},
                "& .MuiTableCell-root":{borderBottom:'none',textAlign:"center"},
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{color:`${colors.grey[100]} !important`}}}>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sport Name</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Sports.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map((sC)=>(
                                        <TableRow key={sC.id}>
                                            <TableCell>{sC.Name.En} - {sC.Name.Ar}</TableCell>
                                            <TableCell color='secondary'>
                                                <IconButton onClick={()=>{}}>
                                                    <PreviewIcon />
                                                </IconButton>
                                                <IconButton onClick={()=>handleShowImageEditor(sC)} color='secondary'>
                                                    <ImageIcon />
                                                </IconButton>
                                                <IconButton onClick={()=>handleEditSportEditor(sC)} >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={Sports.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>  
            </Box>
    </Box>
  )
}

export default Sports